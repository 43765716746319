var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"m-0  justify-content-center"},[_c('b-col',{staticClass:"content-header",attrs:{"cols":"9"}},[_c('h2',{staticClass:"content-header-title float-left m-0 pr-1 mr-1"},[_vm._v(" "+_vm._s(_vm.$route.meta.pageTitle)+" ")]),_c('div',{staticClass:"content-header-search mr-1"},[_c('h3',{staticClass:"m-0"},[_vm._v(_vm._s((_vm.propertyForm.civility==0 ? 'M. ' : _vm.propertyForm.civility==1 ? 'Mme ' : '') + _vm.propertyForm.firstName+ ' ' + _vm.propertyForm.lastName))])]),_c('div',{staticClass:"content-header-actions d-flex"},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"XIcon","size":"24"},on:{"click":function($event){return _vm.cancel()}}})],1)])],1),(_vm.isLoadingProperty)?_c('div',{staticClass:"content-loader-center m-0 h-100"},[_vm._m(0)]):_c('b-row',{staticClass:"content-scrollable-sticky m-0 h-100 justify-content-center"},[_c('b-col',{staticClass:"content-scrollable-sticky-main",attrs:{"cols":"9"}},[_c('validation-observer',{ref:"formCreateProperty"},[_c('b-form',{attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"my-0",attrs:{"id":"general"}},[_c('b-row',{staticClass:"my-0"},[_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"12"}},[_c('label',{attrs:{"for":"address'"}},[_vm._v("Adresse")]),_c('validation-provider',{attrs:{"name":"Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-typeahead-bootstrap',{staticClass:"not-autocomplete",attrs:{"type":"text","state":errors.length > 0 ? false : null,"id":"address","ieCloseFix":false,"data":_vm.autocompleteAddressData,"serializer":function (item) { return item.properties.label; },"showAllResults":true,"placeholder":"Rechercher une adresse ou saisissez : Rue et n° de rue","autocomplete":"nope","aria-autocomplete":"nope"},on:{"input":function($event){return _vm.autocompleteAddressSource()},"hit":_vm.autocompleteAddressSelected},scopedSlots:_vm._u([{key:"suggestion",fn:function(ref){
var data = ref.data;
var htmlText = ref.htmlText;
return [_c('div',{staticClass:"item"},[_c('small',[_vm._v(_vm._s(data.properties.name))]),_c('span',[_vm._v(_vm._s(data.properties.postcode+ ' ' + data.properties.city))])])]}}],null,true),model:{value:(_vm.propertyForm.address),callback:function ($$v) {_vm.$set(_vm.propertyForm, "address", $$v)},expression:"propertyForm.address"}}),_c('small',{staticClass:"text-danger mb-1"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',{staticClass:"my-0"},[_c('b-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"addressComplement"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-1",attrs:{"id":"addressComplement","state":errors.length > 0 ? false : null,"placeholder":"Bâtiment, Appartement.."},model:{value:(_vm.propertyForm.addressComplement),callback:function ($$v) {_vm.$set(_vm.propertyForm, "addressComplement", $$v)},expression:"propertyForm.addressComplement"}})]}}])})],1)],1),_c('b-row',{staticClass:"my-0"},[_c('b-col',{staticClass:"py-0",attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"name":"zipcode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-1",attrs:{"id":"zipcode","state":errors.length > 0 ? false : null,"placeholder":"Code postal"},model:{value:(_vm.propertyForm.zipCode),callback:function ($$v) {_vm.$set(_vm.propertyForm, "zipCode", $$v)},expression:"propertyForm.zipCode"}},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1),_c('b-col',{staticClass:"py-0",attrs:{"cols":"9"}},[_c('validation-provider',{attrs:{"name":"city"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-1",attrs:{"id":"city","state":errors.length > 0 ? false : null,"placeholder":"Ville"},model:{value:(_vm.propertyForm.city),callback:function ($$v) {_vm.$set(_vm.propertyForm, "city", $$v)},expression:"propertyForm.city"}},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)],1),_c('b-row',{staticClass:"my-0"},[_c('b-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"country"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-1",attrs:{"id":"country","state":errors.length > 0 ? false : null,"placeholder":"Pays"},model:{value:(_vm.propertyForm.country),callback:function ($$v) {_vm.$set(_vm.propertyForm, "country", $$v)},expression:"propertyForm.country"}},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)],1),_c('b-row',{staticClass:"my-0"},[_c('b-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('label',{attrs:{"for":"address'"}},[_vm._v("Coordonnées GPS")])]),_c('b-col',{staticClass:"py-0",attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"name":"latitude"}},[_c('b-form-input',{staticClass:"mb-0",attrs:{"id":"latitude","placeholder":"Latitude"},model:{value:(_vm.propertyForm.latitude),callback:function ($$v) {_vm.$set(_vm.propertyForm, "latitude", $$v)},expression:"propertyForm.latitude"}})],1)],1),_c('b-col',{staticClass:"py-0",attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"name":"longitude"}},[_c('b-form-input',{staticClass:"mb-0",attrs:{"id":"longitude","placeholder":"Longitude"},model:{value:(_vm.propertyForm.longitude),callback:function ($$v) {_vm.$set(_vm.propertyForm, "longitude", $$v)},expression:"propertyForm.longitude"}})],1)],1)],1),_c('b-row',{staticClass:"my-0"},[_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"12"}},[_c('small',{staticClass:"cursor-pointer text-primary",on:{"click":_vm.displayFormComplement}},[_vm._v(_vm._s(_vm.displayMore ? '- Masquer les informations complémentaires' : '+ Afficher les informations complémentaires')+" ")])])],1)],1),(_vm.displayMore)?_c('div',{attrs:{"id":"complement"}},[_c('b-row',{staticClass:"my-0"},[_c('b-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Taxe foncière de référence","label-for":"taxProvisioned"}},[_c('validation-provider',{attrs:{"name":"taxProvisioned"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"taxProvisioned","state":errors.length > 0 ? false : null},model:{value:(_vm.propertyForm.taxProvisioned),callback:function ($$v) {_vm.$set(_vm.propertyForm, "taxProvisioned", $$v)},expression:"propertyForm.taxProvisioned"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,774360962)})],1)],1),_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Surface","label-for":"area"}},[_c('validation-provider',{attrs:{"name":"area"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"area","state":errors.length > 0 ? false : null},model:{value:(_vm.propertyForm.area),callback:function ($$v) {_vm.$set(_vm.propertyForm, "area", $$v)},expression:"propertyForm.area"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,415164034)})],1)],1)],1)],1):_vm._e()])],1),_c('b-row',{staticClass:"my-0"},[_c('b-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-content-between mt-2",staticStyle:{"border-top":"1px solid #e2e2e2","padding-top":"0.5rem"}},[_c('div',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.cancel()}}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftIcon"}}),_vm._v(" Annuler ")],1),(_vm.propertyForm.id)?_c('b-button',{staticClass:"btn-icon ml-2",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.archivePropertyLocal(_vm.propertyForm.id, _vm.propertyForm.firstName+' '+_vm.propertyForm.lastName)}}},[_c('feather-icon',{attrs:{"icon":"ArchiveIcon"}})],1):_vm._e()],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":_vm.create}},[(_vm.propertyForm.id)?_c('feather-icon',{attrs:{"icon":"SaveIcon"}}):_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_vm._v(" "+_vm._s(_vm.propertyForm.id ? 'Modifier' : 'Ajouter')+" ")],1)],1)])],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center flex-center"},[_c('div',{staticClass:"spinner-border text-primary",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Chargement...")])]),_c('br'),_vm._v(" Chargement du formulaire... ")])}]

export { render, staticRenderFns }